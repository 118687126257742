export default {
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      privilegeList: []
    };
  },
  mounted() {
    this.getPrivilegeList();
  },
  methods: {
    async getPrivilegeList() {
      this.$store.commit('system/setPrivilegeLoading', true);
      try {
        await this.$store.dispatch('system/getPrivilegeList');
        this.privilegeList = this.$store.state.system.privilegeList;
      } catch (err) {
        this.$store.commit('system/setPrivilegeLoading', false);
        return;
      }
    }
  }
};